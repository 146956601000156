import React from 'react';
import { Linkedin } from 'react-bootstrap-icons';

export default function Navbar() {
    return (
        <>
            <div
                className="d-flex justify-content-between"
                style={{
                    backgroundColor: '#E8F1E9',
                    height: '112px',
                }}
            >
                <div className="p-3 d-flex align-items-center">
                    <img
                        src="k_logo.svg"
                        alt="Primary Logo"
                        height="80px"
                    />
                    <h4 className="m-3">Kipras Tarasas</h4>
                </div>
                <div className="p-3 d-flex align-items-center">
                    <a
                        href="https://www.linkedin.com/in/kipras-tarasas/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Linkedin color="white" size={40} />
                    </a>
                </div>
            </div>
        </>
    );
}
