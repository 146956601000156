import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer';
import Thoughts from './components/Thoughts';

function App() {
    return (
        <div className="App">
            <div
                style={{
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Navbar />
                <div>
                    <Routes>
                        <Route
                            path="/thoughts"
                            element={<Thoughts />}
                        />
                        <Route path="/*" element={<Home />} />
                    </Routes>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default App;
