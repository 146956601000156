import { Link } from 'react-router-dom';

export default function Thoughts() {
    return (
        <>
            Sorry, I did not <i>think</i> yet...
            <br />
            <Link to="/">Go back</Link>
        </>
    );
}
