import React from 'react';
import WhoAmI from './components/WhoAmI';
import Other from './components/Other';
export default function Home() {
    return (
        <>
            <WhoAmI />
            <Other />
        </>
    );
}
