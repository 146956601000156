import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function Other() {
    return (
        <>
            <h3 className="pt-5">More about me 🎤</h3>
            <Container className="my-3 px-3 d-flex justify-content-center">
                <Row>
                    <Col lg={4} sm={6} className="my-2">
                        <Card className="h-100 shadow" border="light">
                            <Card.Header
                                as="h5"
                                style={{ backgroundColor: '#E8F1E9' }}
                            >
                                Work experience
                            </Card.Header>
                            <Card.Body className="text-start">
                                <Card.Text>
                                    I've worked for small and big
                                    organisations, for big and small
                                    teams - all with great success.
                                    I've created microservices, API's,
                                    BFF's and monoliths. Led a medium
                                    sized front-end team while being a
                                    key back-end developer. I always
                                    set out to achieve great things
                                    🏔️. I'm pragmatic but imaginative
                                    where it needs be.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} sm={6} className="my-2">
                        <Card className="h-100 shadow" border="light">
                            <Card.Header
                                as="h5"
                                style={{ backgroundColor: '#E8F1E9' }}
                            >
                                "À la carte"
                            </Card.Header>
                            <Card.Body className="text-start">
                                <Card.Text>
                                    I also freelance on small to
                                    medium sized projects. With every
                                    project I consult on things, such
                                    as:
                                    <ul>
                                        <li>Hosting;</li>
                                        <li>Deployment;</li>
                                        <li>Product scale;</li>
                                        <li>Design;</li>
                                        <li>Technologies.</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} sm={6} className="my-2">
                        <Card className="h-100 shadow" border="light">
                            <Card.Header
                                as="h5"
                                style={{ backgroundColor: '#E8F1E9' }}
                            >
                                It's just my opinion!
                            </Card.Header>
                            <Card.Body className="text-start">
                                <Card.Text>
                                    Pen and paper is <b>not</b> my
                                    choice of gear but a keyboard is -
                                    I sometimes write and share my
                                    opinion on technologies and other
                                    stuff that "float my boat".
                                    <br />
                                    <Link to="thoughts">
                                        Read my thoughts here
                                    </Link>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
