import React from 'react';

export default function Footer() {
    return (
        <>
            <div
                style={{
                    height: '70px',
                    backgroundColor: '#081C15',
                    color: '#D8F3DC',
                }}
                className="d-flex justify-content-center align-items-center"
            >
                <img
                    src="k_logo_secondary.svg"
                    height="80%"
                    alt="Secondary Logo"
                />
                <span className="mx-2">kipras@tarasas.lt</span>
            </div>
        </>
    );
}
