import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

export default function WhoAmI() {
    return (
        <>
            <h3 className="pt-5">About the formal me 👋</h3>

            <Container className="mt-3 px-3 d-flex justify-content-center">
                <Row>
                    <Col lg={4} sm={6} className="my-2">
                        <Card className="h-100 shadow" border="light">
                            <Card.Header
                                as="h5"
                                style={{ backgroundColor: '#E8F1E9' }}
                            >
                                Who am I?
                            </Card.Header>
                            <Card.Body className="text-start">
                                <Card.Text>
                                    First and foremost, I'm a
                                    full-stack web&nbsp;🕸️ developer.
                                    My primary stack is:
                                    <ul>
                                        <li>Node.js;</li>
                                        <li>MongoDB/PostgreSQL;</li>
                                        <li>Ubuntu;</li>
                                        <li>
                                            React (MUI/Bootstrap).
                                        </li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} sm={6} className="my-2">
                        <Card className="h-100 shadow" border="light">
                            <Card.Header
                                as="h5"
                                style={{ backgroundColor: '#E8F1E9' }}
                            >
                                I also am...
                            </Card.Header>
                            <Card.Body className="text-start">
                                <Card.Text>
                                    A devops engineer 🛠️, primarily
                                    working with:
                                    <ul>
                                        <li>Docker;</li>
                                        <li>AWS;</li>
                                        <li>Terraform;</li>
                                        <li>Github actions;</li>
                                        <li>Taskfile.</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} sm={6} className="my-2">
                        <Card className="h-100 shadow" border="light">
                            <Card.Header
                                as="h5"
                                style={{ backgroundColor: '#E8F1E9' }}
                            >
                                Other skills/competencies include...
                            </Card.Header>
                            <Card.Body className="text-start">
                                <Card.Text>
                                    <ul>
                                        <li>
                                            Typescript, PHP, Go, C,
                                            C++, C#, Python and
                                            others;
                                        </li>
                                        <li>
                                            Github and Atlassian
                                            products;
                                        </li>
                                        <li>
                                            SQL and NoSQL databases;
                                        </li>
                                        <li>
                                            Various Linux OS's (Ubuntu
                                            💘);
                                        </li>
                                        <li>
                                            Adobe and Microsoft
                                            suites.
                                        </li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
